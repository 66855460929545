import React, { Component } from 'react'

export default class Timeline extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-experience" data-section="timeline">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">highlights</span>
                                <h2 className="colorlib-heading animate-box">Timeline</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="timeline-centered">

                                <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-1">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Business Unit Developer Intern at <a href="https://www.neuronestech.com/">Neurones Technologies</a> <span>(Abidjan, Côte D'Ivoire) July 2021 - present</span></h2>
                                                
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-2">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Full Stack Web Development Certificate <span>(Georgia Institute of Technology) August 2018 - February 2019</span></h2>
                                                <p>I have joined a boot camp program on Full Stack Web Development. I was able to learn many front-end and back-end web development skills such as the MERN stack (Mongo DB, Express JS, React JS, Node JS). </p>
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-3">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Food Service Worker at <a href="https://www.sjacfoodgroups.com/">SJAC Food Groups</a> <span>(Smyrna, Georgia) January 2016 - April 2021</span></h2>
                                                <p>I have worked in food service during this period. I have learned how to become a reliable team member but also how to excel in multitasking in a fast-paced environment. </p>
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-4">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Internship at <a href="https://www.univ-na.ci/">Université Nangui Abrogoua</a> <span>(Abidjan, Côte d'Ivoire) March 2015 - May 2015</span></h2>
                                                <p>I had an internship as a Database Assistant at Université Nangui Abrogoua. My main role was to enter the data of students (grades, personal info...etc) into a database. </p>
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-5">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Internship at <a href="https://advantageconseils.com/">Advantage Conseils</a> <span>(Abidjan, Côte D'ivoire) January 2015 - March 2015</span></h2>
                                                <p>I had an internship as an Illustrator at a digital marketing firm called "Advantage Conseils". I've learned how to use some design tools such as Adobe Photoshop, Adobe Illustrator CS6...etc to create flyers, cards...etc. </p>
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-6">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Undergraduate education <span>(Savannah State University) August 2010 - December 2014</span></h2>
                                                <p>I have pursued my undergraduate education at Savannah State University with Computer Science as my major.</p>
                                            </div>
                                        </div>
                                    </article>
                                    
                                    <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-none">
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}