import React, { Component } from 'react'

export default class Experience extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-experience" data-section="experience">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">Experience</span>
                                <h2 className="colorlib-heading animate-box">Work Experience</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="timeline-centered">

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInRight">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-4">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Business Unit Cloud & Managed Services developer intern at <a href="https://www.neuronestech.com/">Neurones Technologies</a><span> (Abidjan, Côte d'Ivoire) July 2021 - present</span></h2>
                                                
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-3">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Food service worker at <a href="https://www.sjacfoodgroups.com/">SJAC Food Groups</a><span> (Smyrna, Georgia) Jan 2016 - April 2021</span></h2>
                                                <ul>
                                                    <li>Reliable team player</li>
                                                    <li>Excellent at multitasking</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInRight">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-2">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Database Assistant intern at <a href="https://www.univ-na.ci/">Université Nangui Abrogoua</a> <span>(Abidjan, Côte D'ivoire) March 2015 - May 2015</span></h2>
                                                <ul>
                                                    <li>Provided administrative support by assisting with data entry and management. </li>
                                                    <li>Attention to detail: Ability to ensure that data is correctly entered and secured and that it’s accessible by authorized personnel. </li>
                                                    <li>Team coordination: Excellent at working with teams. </li>
                                                    <li>Time management: Ability to work efficiently in a given amount of time. </li>
                                                    <li>Communication skills: Ability to provide assistance to employees. </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </article>

                                    <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-1">
                                                <i className="icon-pen2" />
                                            </div>
                                            <div className="timeline-label">
                                                <h2>Illustrator intern at <a href="https://advantageconseils.com/">Advantage Conseils</a> <span>(Abidjan, Côte d'Ivoire) January 2015 - March 2015</span></h2>
                                                <ul>
                                                    <li>Utilized creative abilities to create ads (flyers, cards...) using Adobe Illustrator CS6.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </article>
                            
                                    <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                                        <div className="timeline-entry-inner">
                                            <div className="timeline-icon color-none">
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}