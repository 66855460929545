import React, { Component } from 'react'
//import ScriptTag from 'react-script-tag';

export default class Sidebar extends Component {
    render() {
        return (
            <div>
                <div>
                    <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
                    <aside id="colorlib-aside" className="border js-fullheight">
                        <div className="text-center">
                            <div className="author-img" style={{ backgroundImage: 'url(images/about.jpeg)' }} />
                            <h1 id="colorlib-logo"><a href="index.html">Mike Diallo</a></h1>
                            {/*<span className="email"><i className="icon-mail"></i> diallomikey13@yahoo.fr</span>*/}
                        </div>
                        <nav id="colorlib-main-menu" role="navigation" className="navbar">
                            <div id="navbar" className="collapse">
                                <ul>
                                    <li className="active"><a href="#home" data-nav-section="home">Home</a></li>
                                    <li><a href="#about" data-nav-section="about">About</a></li>
                                    {/*<li><a href="#" data-nav-section="projects">Projects</a></li>
                  <li><a href="#" data-nav-section="blog">Blog</a></li>*/}
                                    <li><a href="#skills" data-nav-section="skills">Skills</a></li>
                                    <li><a href="#education" data-nav-section="education">Education</a></li>
                                    <li><a href="#experience" data-nav-section="experience">Experience</a></li>
                                    <li><a href="#projects" data-nav-section="projects">Projects</a></li>
                                    <li><a href="#timeline" data-nav-section="timeline">Timeline</a></li>
                                    <li><a href="#contact" data-nav-section="contact">Contact</a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className="colorlib-footer">
                            <ul>
                                <li><a href="https://www.linkedin.com/in/mikediallo/" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin2" /></a></li>
                                <li><a href="https://github.com/Miked13" target="_blank" rel="noopener noreferrer"><i className="icon-github"></i></a></li>
                            </ul>
                            <small> &copy; Mike Diallo 2022</small>
                        </div>
                        
                    </aside>
                </div>
            </div>
        )
    }
}