import React, { Component } from 'react'

export default class Projects extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-work" data-section="projects">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                                <span className="heading-meta">Projects</span>
                                <h2 className="colorlib-heading animate-box">Highlited Projects</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
                                <div className="project" style={{ backgroundImage: 'url(images/Hangman-game.png)' }}>
                                    <div className="desc">
                                        <div className="con">
                                            <h3><a href="https://miked13.github.io/Word-Guess-game/">Hangman Game</a></h3>
                                            <span>This is a game about guessing the correct word by typing letters from the keyboard with a limited number of tries. The user has to guess the correct rock band or rock music artist.</span>
                                            <p className="icon">
                                                {/*
                                                <span><a href="#"><i className="icon-share3" /></a></span>
                                                <span><a href="#"><i className="icon-eye" /> 100</a></span>
                                                <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
                                <div className="project" style={{ backgroundImage: 'url(images/Crystal-collector.png)' }}>
                                    <div className="desc">
                                        <div className="con">
                                            <h3><a href="https://miked13.github.io/Crystals-Collector">Crystals Collector</a></h3>
                                            <span>This is a game about collecting a random number of crystals. The user has to guess the correct value of each crystals in order to collect the total number of crystals. </span>
                                            <p className="icon">
                                                {/*
                                                <span><a href="#"><i className="icon-share3" /></a></span>
                                                <span><a href="#"><i className="icon-eye" /> 100</a></span>
                                                <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
                                <div className="project" style={{ backgroundImage: 'url(images/Trivia-game.png)' }}>
                                    <div className="desc">
                                        <div className="con">
                                            <h3><a href="https://miked13.github.io/Trivia-Game/">Trivia Game</a></h3>
                                            <span>This is a trivia game about Philosophers.</span>
                                            <p className="icon">
                                                {/*
                                                <span><a href="#"><i className="icon-share3" /></a></span>
                                                <span><a href="#"><i className="icon-eye" /> 100</a></span>
                                                <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 animate-box" data-animate-effect="fadeInBottom">
                                <div className="project" style={{ backgroundImage: 'url(images/GifTastic.png)' }}>
                                    <div className="desc">
                                        <div className="con">
                                            <h3><a href="https://miked13.github.io/GifTastic/">Giftasctic</a></h3>
                                            <span>This application allows the user to add a button to display GIFS based on a keyword. The theme for this game is cartoons.</span>
                                            <p className="icon">
                                                {/*
                                                <span><a href="#"><i className="icon-share3" /></a></span>
                                                <span><a href="#"><i className="icon-eye" /> 100</a></span>
                                                <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
                                <div className="project" style={{ backgroundImage: 'url(images/spotify-this-song.png)' }}>
                                    <div className="desc">
                                        <div className="con">
                                            <h3><a href="https://github.com/Miked13/liri-node-app">LIRI App</a></h3>
                                            <span>The Language Interpretation and Recognition Interface (LIRI) app is able to execute a keyword based on four command lines: spotify-this-song, movie-this, concert-this and do-what-it-says. The app is accessible through the terminal.</span>
                                            <p className="icon">
                                                {/*
                                                <span><a href="#"><i className="icon-share3" /></a></span>
                                                <span><a href="#"><i className="icon-eye" /> 100</a></span>
                                                <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
                                <div className="project" style={{ backgroundImage: 'url(images/restoFinder.png)' }}>
                                    <div className="desc">
                                        <div className="con">
                                            <h3><a href="https://miked13.github.io/Restaurant-Finder/">Food Locator</a></h3>
                                            <span>The purpose of this project is to display a list of restaurants and their information based on keywords about food.</span>
                                            <p className="icon">
                                                {/*
                                                <span><a href="#"><i className="icon-share3" /></a></span>
                                                <span><a href="#"><i className="icon-eye" /> 100</a></span>
                                                <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 animate-box">
                                {/*<p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload" /></a></p>*/}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}