import React, { Component } from 'react'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", messages: "" };
    }

    handleSubmit = e => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...this.state })
        })
          .then(() => alert("Success!"))
          .catch(error => alert(error));
  
        e.preventDefault();
      };
  
    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, subject, messages } = this.state;
        return (
            <section className="colorlib-contact" data-section="contact">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">Get in Touch</span>
                            <h2 className="colorlib-heading">Contact</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                                <div className="colorlib-icon">
                                    {/*<i className="icon-globe-outline" />*/}
                                    <i className="icon-mail" />
                                </div>
                                <div className="colorlib-text">
                                    <p><a href="mailto: diallomikey13@gmail.com">diallomikey13@gmail.com</a></p>
                                </div>
                            </div>
                            <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                                <div className="colorlib-icon">
                                    <i className="icon-map" />
                                </div>
                                <div className="colorlib-text">
                                    <p> Smyrna, Georgia USA</p>
                                </div>
                            </div>
                            <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                                <div className="colorlib-icon">
                                    <i className="icon-phone" />
                                </div>
                                <div className="colorlib-text">
                                    <p><a href="cellphone://">+1 (478)-952-9238</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-md-push-1">
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
                                    <form action onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name" name="name" value={name} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email" name="email" value={email} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Subject" name="subject" value={subject} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="messages" id="message" cols={30} rows={7} className="form-control" placeholder="Message"  value={messages} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="submit" className="btn btn-primary btn-send-message" defaultValue="Send Message" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}