import React, { Component } from 'react';
import './App.css';
import Home from './components/home'
import About from './components/about'
import Contact from './components/contact'
//import Work from './components/work'
import Experience from './components/experience'
import Education from './components/education'
//import Services from './components/services'
import Skills from './components/skills'
import Sidebar from './components/sidebar'
import Timeline from './components/timeline'
import Projects from './components/projects'

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
        <Sidebar></Sidebar>
          <div id="colorlib-main">
            <Home></Home>
            <About></About>
            <Skills></Skills>
            <Education></Education>
            <Experience></Experience>
            <Projects></Projects>
            <Timeline></Timeline>
            <Contact></Contact>
          </div>
        </div>
      </div>
    );
  }
}

export default App;